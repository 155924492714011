.refresh-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 12px;

  width: 125px;
  height: 44px;

  background: #0d6efd;
  border: 1px solid #0d6efd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #ffffff;
  border-radius: 8px;
}
.refresh-button:hover {
  transform: scale(1.1);
}
.bg-processing-file {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #101828;
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 24px;

  width: 338.67px;
  height: 155px;

  background: #e5f9e0;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(229, 249, 224, 0.1),
    0px 1px 2px rgba(229, 249, 224, 0.06);
  border-radius: 8px;
}
.bg-account {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #101828;
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 24px;

  width: 338.67px;
  height: 155px;

  background: #f8fcda;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(248, 252, 218, 0.1),
    0px 1px 2px rgba(248, 252, 218, 0.06);
  border-radius: 8px;
}
.bg-branches {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #101828;
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 24px;

  width: 338.67px;
  height: 155px;

  background: #fdebd1;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(253, 235, 209, 0.1),
    0px 1px 2px rgba(253, 235, 209, 0.06);
  border-radius: 8px;
}
.details-card {
  margin-left: 8rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  /* identical to box height */
  margin-top: -1rem;
  text-align: center;

  color: #101828;
}
.badge-style-count {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: fit-content !important;
  padding: 5px !important;
  height: 22px;
  color: #344054;
  background: #f2f4f7;
  border-radius: 16px;
}

.badge-style-complete-count {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: fit-content !important;
  padding: 5px !important;
  height: 22px;
  color: #027a48;

  background: #ecfdf3;
  border-radius: 16px;
}

.badge-style-failed-count {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: fit-content !important;
  padding: 5px !important;
  height: 22px;

  background: #fef3f2;
  border-radius: 16px;
  color: #b42318;
}
.total-processing-files-contact {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #101828;
  box-sizing: border-box;
  width: 428px;
  height: 155px;

  background: #eeeeff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(238, 238, 255, 0.1),
    0px 1px 2px rgba(238, 238, 255, 0.06);
  border-radius: 8px;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 24px;
}
.total-processing-contact {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  width: 428px;
  height: 155px;

  background: #f0e2e7;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(240, 226, 231, 0.1),
    0px 1px 2px rgba(240, 226, 231, 0.06);
  border-radius: 8px;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 24px;
}
.details-card-contact {
  margin-left: 45%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  /* identical to box height */
  margin-top: -1rem;
  text-align: center;

  color: #101828;
}
.table-overflow-style {
  overflow-x: auto;
}
.no-mrgin-left {
  margin-left: 0px;
}
.flex-align-center {
  align-items: center;
}