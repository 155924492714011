.menu-element-style {
  display: flex;
  padding: 0.4rem 0.6rem;
  gap: 2rem;
  row-gap: 2rem;
  column-gap: 2rem;
  width: 100%;
  background: #fff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 3rem;
  color: #000;
}
.menu-element-style:hover {
  border-radius: 10px;
  background: #f5f9ff;
  cursor: pointer;
  color: #0062f5;
}
.menu-element-style-marked {
  border-radius: 10px;
  background: #f5f9ff;
  cursor: pointer;
  color: #0062f5;
}
