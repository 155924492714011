.h-filters {
  height: 3rem;
  margin-top: inherit;
  width: 10rem;
}

.body-white {
  background-color: #fff;
}

body {
  background-color: #fafcff;
}

.title-style {
  /* width: 1064px;  */
  /* ! causing issue on small screen size for referesh button */
  height: 38px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height, or 127% */

  color: #101828;
}

.subtitle-style {
  width: 1064px;
  height: 24px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #667085;
}

.file-picker-style {
  /* width: 7rem; */
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  color: #101828;
  border-color: #d0d5dd;
  background-color: #fff;
}

.filter-elements-spacing {
  margin-right: 1.5rem;
  color: #101828;
  border-color: #d0d5dd;
  background-color: #fff;
}

.accordion-spacing-style {
  margin-top: 2rem;
}

.border-style-header {
  border-style: none solid none none;
  border-width: 1px;
  border-color: #9a9a9a;
}

.border-style-header-download {
  /* TODO :: when expand of file will display at time position and right should remove [SM]*/
  position: absolute;
  right: 0rem;
  /* TODO ::  to display the vertical line after download change 2nd none -> solid for visibility[SM] */
  border-style: none none none solid;
  border-width: 1px;
  border-color: #9a9a9a;
}

.slash-style {
  margin-top: -30px;
  font-size: 70px;
  color: #0062f5;
}

.errors-dialog-style {
  padding-top: 15px;
}

.header-text-card-style {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

.width-14 {
  width: 16rem;
  margin-left: -2rem;
}

.card-header-style {
  background: #f5f9ff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #0062f5;
}

.label-heading-style {
  display: flex;
  font-size: 1rem;
  gap: 1rem;
  padding: 2px;
  align-items: flex-start;
}

.label-heading-style:hover {
  background-color: #d6e5fc;
  border-radius: 5px;
  cursor: pointer;
}

.close-button-style {
  border: none;
  background: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.hide-file-expand-icon {
  display: none;
}

.download-drawer-position {
  right: -1rem;
}
