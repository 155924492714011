.account-home {
  background-color: #b7ffd8 !important;
}
.account-branches-home {
  background-color: #c4f5fc !important;
}
.contact-home {
  background-color: #e8ffb7 !important;
}

.contact-branches-home {
  background-color: #ffc1cf !important;
}
.updated-by-badge {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 150% */

  text-align: center;

  color: #3538cd;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;

  width: fit-content !important;
  padding: 5px !important;
  height: 22px;

  /* Indigo/50 */

  background: #eef4ff;
  border-radius: 16px;
}
.upper-header {
  background-color: #f5f9ff;
}
.lower-header {
  background-color: #ebf3ff;
}
