.anchor-tag {
  color: white !important;
  text-decoration: none;
}

.anchor-tag:hover {
  color: white !important;
  text-decoration: none;
}

.nexsales-logo {
  width: 5%;
}
.navbar-style {
  background-color: #0d6efd !important;
  margin: 0 !important;
}
