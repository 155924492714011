.thead-style {
  background-color: #f5f9ff;
  font-size: 12px;
  color: #667085;
  border-bottom: 1px solid #eaecf0;
  border-color: #eaecf0;
}
.thead-style th {
  font-weight: 500;
  font-family: "Montserrat";
  line-height: 18px;
}
.IfFep {
  width: 1%;
  white-space: nowrap;
  max-width: 255px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}
.table-style {
  padding: inherit;
  padding-right: 4rem;
  padding-left: 4rem;
}
.badge-style {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: fit-content !important;
  padding: 5px !important;
  height: 22px;
  color: #344054;
  background: #f2f4f7;
  border-radius: 16px;
}
.dot-style {
  position: relative;
  width: 6px;
  height: 6px;
  left: 1px;
  top: 1px;
  border-radius: 50%;

  background: #667085;
}

.badge-style-complete {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: fit-content !important;
  padding: 5px !important;
  height: 22px;
  padding: 5px;
  color: #027a48;

  background: #ecfdf3;
  border-radius: 16px;
}
.dot-style-complete {
  position: relative;
  width: 6px;
  height: 6px;
  left: 1px;
  top: 1px;
  border-radius: 50%;
  background: #027a48;
}

.badge-style-failed {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: fit-content !important;
  padding: 5px !important;
  height: 22px;
  padding: 5px;

  background: #fef3f2;
  border-radius: 16px;
  color: #b42318;
}
.dot-style-failed {
  position: relative;
  width: 6px;
  height: 6px;
  left: 1px;
  top: 1px;
  border-radius: 50%;

  background: #f04438;
}